import { featureCollection, point } from '@turf/helpers';
import { getGeolocalisation, startGeolocalisation, stopGeolocalisation, storeFootprint } from '@/api/geolocalisations';

import Vue from 'vue';

import bbox from '@turf/bbox';
import center from '@turf/center';
import { computePose } from '@/api/pose.js';
import { getAttributes } from '@/api/image';
import { getCountryCode } from '@/api/locations.js';

const state = {
  id: null,
  image_id: null,
  step: 0, //0 Mode Selection, 1 Split, 2 Location, 3 Direction, 4 Align, 5 Observations, 6 Bravo, 7 Validate
  user_id: 14,
  currentImage: {
    id: null,
    title: null,
    caption: null,
    collection: {
      name: null,
      link: null
    },
    owner: {
      name: null,
      link: null
    },
    photographer: {
      name: null,
      link: null
    },
    license: null,
  },
  selectedImageSplit: null,
  saveError: null,
  panoramaImages: [],
  potentialPanorama: null,
  editPanorama: {},
  panoramaLocationLocked: true, // true = shots location are locked to first shot location
  georeferencingFirstShot: true, // true = no shot of panorama has been georeferenced yet.
  panoramaUnlockOption: false, // true = give the option to compute without blocking the location to first shot
  GCPs: {
    currentId: -1,
    selectedId: null,
    dict: []
  },
  initialPose: null,
  // Reference
  currentPose: {
    latitude: null,
    longitude: null,
    altitude: null,
    azimuth: null,
    tilt: null,
    roll: null,
    focal: null,
    heightAboveGround: null,
    locationLocked: false,
    country_iso_a2: null,
    geolocalisation_id: null,
    gltf_url: null,
    regionByPx: null
  },
  // Pose to be send for calculation
  currentPoseApriori: {
    latitude: null,
    longitude: null,
    altitude: null,
    azimuth: null,
    tilt: null,
    roll: null,
    focal: null,
    exact: null,
    heightAboveGround: null,
    locationLocked: false,
    geolocalisation_id: null,
    gltf_url: null,
    regionByPx: null
  },
  //CURRENTPOSES composite_image
  currentPoses: null,
  map: {
    location: [8.224, 46.625], // Center on Switzerland by default
    zoom: 8
  }
};

const defaultGCP = {
  // 3D World GCP
  latitude: null,
  longitude: null,
  altitude: null,
  X: null,
  Y: null,
  Z: null,
  // Image GCP
  x: null,
  y: null
};

const getters = {
  isCurrentImageLoaded: state => {
    return state.currentImage?.media !== null && typeof state.currentImage?.media !== 'undefined';
  },
  isCurrentImageComposite: state => {
    if (state.currentImage.framing_mode === 'single_image') {
      return false;
    } else if(state.currentImage.state === 'initial') { //from route geolocalise/:id/align
      return state.currentImage.framing_mode === 'composite_image' ? true : false;
    } else if (state.currentImage.state === 'waiting_validation' || state.currentImage.state === 'validated') { //from route validate/:id
      // framing_mode can't be used because the volunteer might have georeferenced the image in single_image mode.
      return state.currentImage.poses.length > 1 ? true : false;
    }
  },
  numberGCPs: state => {
    return state.GCPs.dict.length;
  },
  numberGCPsMissing: state => {
    return 6 - state.GCPs.dict.length;
  },
  numberGCPsMissingForFirstPanoramaShot: state => {
    return 10 - state.GCPs.dict.length;
  },
  numberGCPsWithError: state => {
    return state.GCPs.dict.filter(gcp => gcp?.high).length;
  },
  numberGCPsErrorHigh: state => {
    return state.GCPs.dict.filter(gcp => gcp.high === 'high').length;
  },
  areGCPsErrorHigh: (state, getters) => {
    return (getters['numberGCPsErrorHigh'] >= 1);
  },
  surfaceCovered: state => {
    let surfaceRatio = 0;
    if (state.selectedImageSplit) {
      // Rectangle surface
      const areaImage = state.selectedImageSplit.rect['w'] * state.selectedImageSplit.rect['h'];
      // Surface covered by the gcps
      const listPoints = state.GCPs.dict.map(gcp => point([gcp.x ? gcp.x : 0, gcp.y ? gcp.y : 0]));
      const bboxx = bbox(featureCollection(listPoints));
      const areaBbox = (bboxx[2] - bboxx[0]) * (bboxx[3] - bboxx[1]);
      // Compute the ratio of the surface
      surfaceRatio = Math.round((areaBbox / areaImage) * 100);
    }
    return surfaceRatio;
  },
  isEnoughSurfaceCovered: (state, getters) => {
    return (getters['surfaceCovered'] >= 30);
  },
  numberAllGCPsCalculated: state => {
    return state.GCPs.dict.filter(gcp => Object.prototype.hasOwnProperty.call(gcp, 'xReproj')).length;
  },
  numberAllGCPsNotCalculated: (state, getters) => {
    return state.GCPs.dict.length - getters['numberAllGCPsCalculated'];
  },
  areAllGCPsCalculated: (state, getters) => {
    return getters['numberAllGCPsNotCalculated'] === 0;
  },
  isCurrentGCPOnImage: state => {
    return state.GCPs.dict[state.GCPs.currentId]?.x !== null; // x is randomly chosen
  },
  isCurrentGCPOnWorld: state => {
    return state.GCPs.dict[state.GCPs.currentId]?.Z !== null; // Z is randomly chosen
  },
  isCurrentGCPComplete: (state, getters) => {
    return getters['isCurrentGCPOnImage'] && getters['isCurrentGCPOnWorld'];
  },
  geoloc_id: state => { return state.id; },
  currentPose: state => { return state.currentPose; },
  currentPanorama: state => {
    const panorama = state.panoramaImages.find(e => e.imageId === state.currentImage.id);
    return panorama ? panorama : [];
  },
};

const mutations = {
  SET_CURRENT_IMAGE: (state, currentImage) => {
    state.currentImage = currentImage;
  },
  SET_CURRENT_USER: (state, user_id) => {
    state.user_id = user_id;
  },
  SET_CURRENT_GEOLOCALISATION: (state, geoloc) => {
    state.id = geoloc.id;
    state.image_id = geoloc.image_id;
    state.user_id = geoloc.user_id;
  },
  SET_CURRENT_PANORAMA_GEOLOCALISATION: (state, geoloc) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages[panoramaIndex].geolocId[geoloc.panorama.index] = {
      id: geoloc.geoloc.id,
      image_id: geoloc.geoloc.image_id,
      user_id: geoloc.geoloc.user_id,
    };
  },
  RESET_CURRENT_GEOLOCALISATION: state => {
    state.id = null;
    state.image_id = null;
    state.GCPs = {
      currentId: -1,
      selectedId: null,
      dict: []
    };
    state.selectedImageSplit = null;
    state.panoramaImages = [];
  },
  INIT_GCP: state => {
    state.GCPs.dict[state.GCPs.currentId] = defaultGCP;
  },
  RESET_GCP_CURRENT_ID: state => {
    state.GCPs.currentId = -1;
  },
  INCREMENT_GCP_CURRENT_ID: state => {
    state.GCPs.currentId += 1;
  },
  DECREMENT_GCP_CURRENT_ID: state => {
    state.GCPs.currentId -= 1;
  },
  LOAD_GCPs_SET: (state, GCPs) => {
    state.GCPs.dict = GCPs;
    state.GCPs.currentId = state.GCPs.dict.length - 1;
    state.GCPs.selectedId = null;
  },
  FILL_GCP: (state, payload) => {
    const { id, ...params } = payload;
    state.GCPs.dict[id] = { ...state.GCPs.dict[id], ...params };
  },
  SELECT_GCP: (state, id) => {
    state.GCPs.selectedId = parseInt(id, 10);
  },
  DESELECT_GCP: state => {
    state.GCPs.selectedId = null;
  },
  DELETE_GCP: (state, id) => {
    state.GCPs.dict.splice(id, 1);
  },
  RESET_GCPS: state => {
    state.GCPs.dict = [];
  },
  SET_GCP_ERROR: (state, params) => {
    state.GCPs.dict[params.gcpId].errorClass = params.error;
  },
  RESET_GCPS_ERRORS: state => {
    state.GCPs.dict.map(gcp => {
      delete gcp.errorClass;
      delete gcp.high;
      delete gcp.medium;
      delete gcp.low;
      delete gcp.xReproj;
      delete gcp.yReproj;
    });
  },
  SAVE_MAP_CENTER: (state, payload) => {
    state.map.location = payload;
  },
  SAVE_MAP_ZOOM: (state, payload) => {
    state.map.zoom = payload;
  },
  SET_POSE: (state, pose) => {
    state.currentPose = { ...state.currentPose, ...pose };
    // check values to prevent invalid values being userd in CesiumJS
    if (state.currentPose.roll && state.currentPose.roll > 360) {
      state.currentPose.roll = state.currentPose.roll % 360;
    } else if (state.currentPose.roll && state.currentPose.roll < -360) {
      state.currentPose.roll = state.currentPose.roll % 360;
      state.currentPose.roll = state.currentPose.roll + 360;
    }
  },
  RESET_POSE_ALL: state => {
    state.currentPose = {
      latitude: null,
      longitude: null,
      altitude: null,
      azimuth: null,
      tilt: null,
      roll: null,
      focal: null,
      heightAboveGround: null,
      locationLocked: false,
      country_iso_a2: null,
      geolocalisation_id: null,
      image_id: null,
      gltf_url: null,
      regionByPx: null
    };
    state.currentPoseApriori = {
      latitude: null,
      longitude: null,
      altitude: null,
      azimuth: null,
      tilt: null,
      roll: null,
      focal: null,
      heightAboveGround: null,
      locationLocked: false
    };
    state.currentPoses = null;
  },
  SET_DEFAULT_POSE: (state, view_type) => {
    let tilt = -35;
    let altitude = null;
    let heightAboveGround = 1000;

    switch (view_type) {
      case 'lowOblique':
        tilt = -35;
        heightAboveGround = 1000;
        break;
      case 'highOblique':
        tilt = -65;
        heightAboveGround = 2000;
        break;
      case 'nadir':
        tilt = -90;
        heightAboveGround = 2000;
        break;
      case 'terrestrial':
        tilt = 0;
        heightAboveGround = 20;
        break;
    }

    // Altitude is known from the owner
    if (state.currentPoseApriori.altitude !== null) {
      altitude = state.currentPoseApriori.altitude;
    }

    // Set Tilt
    state.currentPose.tilt = tilt;

    // Set altitude
    state.currentPose.altitude = altitude;
    state.currentPose.heightAboveGround = heightAboveGround;
  },
  LOCK_LOCATION: (state, locked) => {
    state.currentPose.locationLocked = locked;
    state.currentPoseApriori.locationLocked = locked;
  },
  SET_ALTITUDE: (state, altitude) => {
    state.currentPose.altitude = altitude;
  },
  SET_HEIGHT_ABOVE_GROUND: (state, heightAboveGround) => {
    state.currentPose.heightAboveGround = heightAboveGround;
  },
  SET_LONGLAT: (state, params) => {
    state.currentPose.latitude = params[1];
    state.currentPose.longitude = params[0];
  },
  SET_COUNTRY_CODE: (state, countrycode) => {
    state.currentPose.country_iso_a2 = countrycode;
  },
  SET_AZIMUTH: (state, azimuth) => {
    state.currentPose.azimuth = azimuth;
  },
  SET_ROLL: (state, roll) => {
    if (roll > 180) {
      roll = roll - 360;
    }
    state.currentPose.roll = roll;
  },
  SET_APRIORI_POSE: (state, apriori) => {
    state.currentPoseApriori = { ...state.currentPoseApriori, ...apriori };
  },
  REVERT_POSE_FROM_INITIAL_POSE: state => {
    state.currentPose = state.initialPose;
  },
  REVERT_APRIORI_POSE_FROM_POSE: state => {
    state.currentPoseApriori = state.currentPose;
  },
  SAVE_INITIAL_POSE: state => {
    state.initialPose = { ...state.currentPose };
  },
  SET_STEP: (state, stepNumber) => {
    state.step = stepNumber;
  },
  NEXT_STEP: state => {
    if (state.step < 7) state.step++;
  },
  SET_MODE: (state, mode) => {
    state.currentImage.framing_mode = mode;
  },
  SET_PANORAMA_IMAGE: (state, rect) => {
    state.potentialPanorama = {
      images: {
        x: rect.images.x,
        y: rect.images.y,
        width: rect.images.width,
        height: rect.images.height,
      },
      rectangle: {
        x: rect.rectangle.x,
        y: rect.rectangle.y,
        width: rect.rectangle.width,
        height: rect.rectangle.height,
      }
    };
  },
  SAVE_PANORAMA_IMAGE: (state) => {
    if (state.potentialPanorama) {
      const index = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
      if (index === -1) {
        state.panoramaImages.push({
          imageId: state.currentImage.id,
          images: [state.potentialPanorama.images],
          rectangle: [state.potentialPanorama.rectangle],
          GCPs: [[]],
          currentPose: [[]],
          geolocId: [null],
          isGeoloc: [false],
          footprint_geojson: [null],
          error: []
        });
      }
      else {
        state.panoramaImages[index].images.push(state.potentialPanorama.images);
        state.panoramaImages[index].rectangle.push(state.potentialPanorama.rectangle);
        state.panoramaImages[index].GCPs.push([]);
        state.panoramaImages[index].currentPose.push([]);
        state.panoramaImages[index].geolocId.push(null);
        state.panoramaImages[index].isGeoloc.push(false);
        state.panoramaImages[index].footprint_geojson.push(null);
      }
      state.potentialPanorama = null;
    }
  },
  EDIT_PANORAMA_IMAGET: (state, index) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.editPanorama = {
      index: index,
      rectangle: state.panoramaImages[panoramaIndex].rectangle[index],
    };
  },
  UPDATE_PANORAMA_IMAGE: (state, split) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages[panoramaIndex].images[split.index].x = split.images.x;
    state.panoramaImages[panoramaIndex].images[split.index].y = split.images.y;
    state.panoramaImages[panoramaIndex].images[split.index].width = split.images.width;
    state.panoramaImages[panoramaIndex].images[split.index].height = split.images.height;
    state.panoramaImages[panoramaIndex].rectangle[split.index].x = split.rectangle.x;
    state.panoramaImages[panoramaIndex].rectangle[split.index].y = split.rectangle.y;
    state.panoramaImages[panoramaIndex].rectangle[split.index].width = split.rectangle.width;
    state.panoramaImages[panoramaIndex].rectangle[split.index].height = split.rectangle.height;
    state.panoramaImages[panoramaIndex].GCPs[split.index] = [];
    state.panoramaImages[panoramaIndex].currentPose[split.index] = [];
    state.panoramaImages[panoramaIndex].geolocId[split.index] = null;
    state.panoramaImages[panoramaIndex].isGeoloc[split.index] = false;
    state.panoramaImages[panoramaIndex].footprint_geojson[split.index] = null;
  },
  STOP_PANORAMA_IMAGE_EDITION: (state) => {
    state.editPanorama = {};
  },
  DETELE_PANORAMA_IMAGE: (state, index) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages[panoramaIndex].images.splice(index, 1);
    state.panoramaImages[panoramaIndex].rectangle.splice(index, 1);
    state.panoramaImages[panoramaIndex].GCPs.splice(index, 1);
    state.panoramaImages[panoramaIndex].currentPose.splice(index, 1);
    state.panoramaImages[panoramaIndex].geolocId.splice(index, 1);
    state.panoramaImages[panoramaIndex].isGeoloc.splice(index, 1);
    state.panoramaImages[panoramaIndex].footprint_geojson.splice(index, 1);
  },
  SELECT_SPLIT_IMAGE: (state, split) => {
    state.selectedImageSplit = {
      index: split.index,
      rect: {
        x: split.rect.x,
        y: split.rect.y,
        w: split.rect.width,
        h: split.rect.height,
      }
    };
    state.GCPs.dict = split.gpcs;
    state.GCPs.currentId = split.gpcs.length - 1;
  },
  RESET_SPLIT_IMAGE: (state) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages[panoramaIndex].GCPs[state.selectedImageSplit.index] = state.GCPs.dict;
    state.selectedImageSplit = null;
  },
  LOCK_PANORAMA_LOCATION: (state, isLocked) => {
    state.panoramaLocationLocked = isLocked;
  },
  SAVE_GEOLOC_CURRENT_PANORAMA_IMAGE: (state) => {
    try {
      const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
      state.panoramaImages[panoramaIndex].GCPs[state.selectedImageSplit.index] = state.GCPs.dict;
      state.panoramaImages[panoramaIndex].currentPose[state.selectedImageSplit.index] = state.currentPose;
      state.panoramaImages[panoramaIndex].isGeoloc[state.selectedImageSplit.index] = true;
      state.id = state.panoramaImages[panoramaIndex].geolocId[state.selectedImageSplit.index].id;
    } catch (error) {
      console.error(error);
    }
  },
  SET_PANORAMA_FIRST_POSE: (state) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages[panoramaIndex].firstPanoramaPose = state.currentPose;
  },

  STORE_FOOTPRINT: (state, footprint) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    const footprint_geojson = JSON.parse(footprint.footprint);
    state.panoramaImages[panoramaIndex].footprint_geojson[state.selectedImageSplit.index] = footprint_geojson;
  },
  SAVE_STATUS: (state, status) => {
    state.saveError = status;
  },
  STORE_SAVE_ERROR: (state, element) => {
    state.panoramaImages[element.index].error.push(element.error);
  },
  DELETE_CURRENT_PANORAMA: (state) => {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    state.panoramaImages.splice(panoramaIndex, 1);
  },
  CHECK_STATE_PANORAMA: (state) => {
    const currentPanorama = state.panoramaImages.find(e => e.imageId === state.currentImage.id);
    state.georeferencingFirstShot = currentPanorama.isGeoloc.find(e => e === true) ? false : true;
  },
  ENABLE_PANORAMA_UNLOCK_OPTION: (state, isEnabled) => {
    state.panoramaUnlockOption = isEnabled;
  },
};

const actions = {
  async loadImageToGeoloc({ commit, state, dispatch }, params) {
    const response = await getAttributes({ id: params.image_id });

    const { apriori_altitude, apriori_locations, ...image } = response.data;
    commit('SET_CURRENT_IMAGE', { apriori_locations, ...image });
    commit('SET_APRIORI_POSE', { altitude: apriori_altitude });

    // Images are already pre-georeferenced with location and azimuth (e.g. images of imaginerio owner)
    if (image.state === 'waiting_alignment') {
      commit('SET_DEFAULT_POSE', image.view_type);
      commit('SET_POSE', { ...apriori_locations[0], altitude: apriori_altitude });
      commit('SET_APRIORI_POSE', { ...apriori_locations[0], locationLocked: (apriori_locations[0].exact) });
      return;
    }

    // First time: Set default values
    if (state.currentPose.latitude === null) {
      let defaultCentralPoint = state.map.location;
      const defaultView = { location: state.map.location, zoom: 9 };

      // Multiple Apriori locations
      if (apriori_locations.length > 1) {
        const geojson = featureCollection(apriori_locations.map(apriori => point([apriori.longitude, apriori.latitude])));
        defaultView.location = bbox(geojson);
        defaultCentralPoint = center(geojson).geometry.coordinates;
      }

      // Unique Apriori
      if (apriori_locations.length === 1) {
        defaultView.location = defaultCentralPoint = [apriori_locations[0].longitude, apriori_locations[0].latitude];
        defaultCentralPoint = [apriori_locations[0].longitude, apriori_locations[0].latitude];
      }

      // Validation: Image already georeferenced, get the pose from image
      if (apriori_locations.length === 0 && image?.pose?.latitude !== null) {
        defaultView.location = defaultCentralPoint = [image.pose.longitude, image.pose.latitude];
      }

      // Ensure that image has a country code
      if (!state.currentPose.country_iso_a2) {
        dispatch('setCountryCode', defaultCentralPoint);
      }

      commit('SET_LONGLAT', defaultCentralPoint); // Set marker location with default map center
      commit('SAVE_MAP_CENTER', defaultView.location);
      commit('SAVE_MAP_ZOOM', defaultView.zoom);
      commit('SET_DEFAULT_POSE', image.view_type);
    };
  },

  async loadImageToValidate({ commit, dispatch }, params) {
    const response = await getAttributes(params);
    const { pose, ...image } = response.data;
    commit('SET_CURRENT_IMAGE', image);
    await dispatch('setCountryCode', [pose.longitude, pose.latitude]);
  },

  async getGeolocalisationAndPose({ commit }, params) {
    const response = await getGeolocalisation(params);
    const { pose, gcp_json, ...geoloc } = response.data;
    commit('SET_CURRENT_GEOLOCALISATION', geoloc);
    commit('LOAD_GCPs_SET', gcp_json);
    commit('SET_POSE', pose);
    commit('SAVE_INITIAL_POSE');
  },

  async updateUserId({ commit }, params) {
    commit('SET_CURRENT_USER', params);
  },

  // Used in the last step of geolocalisation
  async loadImageBravo({ commit, dispatch }, params) {
    try {
      const imageRes = await getAttributes({ id: params.image_id });
      const { ...image } = imageRes.data;
      commit('SET_CURRENT_IMAGE', image);
      const geolocRes = await getGeolocalisation({ id: state.id });
      const { azimuth, tilt, roll, focal } = geolocRes.data[0];
      const latitude = geolocRes.data[0].location.coordinates[1];
      const longitude = geolocRes.data[0].location.coordinates[0];
      const altitude = geolocRes.data[0].location.coordinates[2];
      const pose = { latitude, longitude, altitude, azimuth, tilt, roll, focal };
      commit('SET_POSE', pose);
      await dispatch('setCountryCode', [pose.longitude, pose.latitude]);
    } catch (error) {
      console.error(error);
    }
  },

  async startNewGeolocalisation({ commit, rootState }, params) {
    const response = await startGeolocalisation({ ...params });
    commit('SET_CURRENT_GEOLOCALISATION', response.data);
  },

  async saveGeolocalisation({ commit, state, rootState }) {
    commit('app/ENABLE_FULL_LOADING', null, { root: true });
    await stopGeolocalisation({
      id: state.id,
      image_id: state.currentImage.id,
      longitude: state.currentPose.longitude,
      latitude: state.currentPose.latitude,
      altitude: state.currentPose.altitude,
      roll: state.currentPose.roll,
      tilt: state.currentPose.tilt,
      azimuth: state.currentPose.azimuth,
      focal: state.currentPose.focal,
      gcps: state.GCPs.dict,
      regionByPx: state.currentPose.regionByPx
    });
    commit('app/DISABLE_FULL_LOADING', null, { root: true });
  },

  saveGeolocCurrentPanoramaImage({ commit }) {
    commit('SAVE_GEOLOC_CURRENT_PANORAMA_IMAGE');
  },

  setPanoramaFirstPose({ commit }) {
    commit('SET_PANORAMA_FIRST_POSE');
  },

  async saveFootprint({ commit, state }, params) {
    await storeFootprint(params.id, {
      latitude: state.currentPose.latitude,
      longitude: state.currentPose.longitude,
      footprint_geojson: JSON.parse(params.footprint)
    });
  },

  storeFootprint({ commit }, footprint) {
    commit('STORE_FOOTPRINT', footprint);
  },

  // Reset all the saved content
  stopLastGeolocalisation({ commit }) {
    commit('RESET_CURRENT_GEOLOCALISATION');
  },

  resetGCPS({ commit }) {
    commit('DESELECT_GCP');
    commit('RESET_GCP_CURRENT_ID');
    commit('RESET_GCPS');
  },

  createGCP({ commit, dispatch }) {
    // Using an action allow to subscribe to before the action take place
    return new Promise((resolve) => {
      dispatch('deselectGCP')
        .then(() => {
          commit('INCREMENT_GCP_CURRENT_ID');
          commit('INIT_GCP');
          resolve();
        });
    });
  },

  fillGCP({ commit }, params) {
    commit('FILL_GCP', params);
  },

  selectGCP({ commit, dispatch }, id) {
    // Using an action allow to subscribe to before the action take place
    return new Promise((resolve) => {
      dispatch('deselectGCP')
        .then(() => {
          commit('SELECT_GCP', id);
          resolve();
        });
    });
  },

  deselectGCP({ commit }) {
    commit('DESELECT_GCP');
  },

  deleteSelectedGCP({ commit, state }) {
    commit('DELETE_GCP', state.GCPs.selectedId);
    commit('DECREMENT_GCP_CURRENT_ID');
    commit('DESELECT_GCP');
  },

  addGCPError({ commit }, params){
    commit('SET_GCP_ERROR', params);
  },

  resetGCPErrors({ commit }) {
    commit('RESET_GCPS_ERRORS');
  },

  setMapSearchLocation({ commit }, location) { },// Action used as event only

  saveMapLocation({ commit }, loc) {
    let { longitude, latitude, zoom } = loc;

    longitude = parseFloat(longitude.toFixed(4));
    latitude = parseFloat(latitude.toFixed(4));
    zoom = parseFloat(zoom.toFixed(2));

    if (longitude && latitude) {
      commit('SAVE_MAP_CENTER', [longitude, latitude]);
    }

    if (zoom) {
      commit('SAVE_MAP_ZOOM', zoom);
    }
  },

  setDefaultPose({ commit }, view_type) {
    commit('SET_DEFAULT_POSE', view_type);
  },

  lockLocation({ commit }, locked) {
    commit('LOCK_LOCATION', locked);
  },

  setAltitude({ commit }, altitude) {
    commit('SET_ALTITUDE', altitude);
  },

  setHeightAboveGround({ commit }, heightAboveGround) {
    commit('SET_HEIGHT_ABOVE_GROUND', heightAboveGround);
  },

  setLongLat({ commit }, longlat) {
    commit('SET_LONGLAT', longlat);
  },

  setCountryCode({ commit }, longlat) {
    return new Promise((resolve, reject) => {
      const [longitude, latitude] = longlat;
      getCountryCode({ longitude, latitude })
        .then(response => {
          commit('SET_COUNTRY_CODE', response.data);
          resolve();
        })
        .catch(error => {
          // Set to world by default.
          commit('SET_COUNTRY_CODE', 'WORLD');
          reject(error);
        });
    });
  },

  setAzimuth({ commit }, azimuth) {
    commit('SET_AZIMUTH', azimuth);
  },

  setRoll({ commit }, roll) {
    commit('SET_ROLL', roll);
  },

  setAprioriPose({ commit }, apriori) {
    commit('SET_APRIORI_POSE', apriori);
  },

  computePose({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      commit('app/ENABLE_FULL_LOADING', null, { root: true });
      //offset GCPs if image cropped
      let imageCropGCPs = state.GCPs.dict;

      //crop region
      let cropRegion;
      if (getters['isCurrentImageComposite']) {
        if (state.currentImage.state === 'initial') { //from route geolocalise/:id/align
          cropRegion = [
            Math.round(state.selectedImageSplit.rect.x),
            Math.round(state.selectedImageSplit.rect.y),
            Math.round(state.selectedImageSplit.rect.w),
            Math.round(state.selectedImageSplit.rect.h)
          ];
        } else if (state.currentImage.state === 'waiting_validation' || (state.currentImage.state === 'validated')) { //from route validate/:id
          cropRegion = [
            state.currentPose.regionByPx[0],
            state.currentPose.regionByPx[1],
            state.currentPose.regionByPx[2],
            state.currentPose.regionByPx[3]
          ];
        }
      } else {
        cropRegion = state.currentImage.media.regionByPx;
      }


      if (cropRegion) {
        imageCropGCPs = state.GCPs.dict.map( gcp => {
          return {
            ...gcp,
            x: gcp.x-cropRegion[0],
            y: gcp.y-cropRegion[1]
          };
        });
      }

      // If composite_image, lock the camera position by default, unless option to unlock location is true.
      let previousPose;

      if (getters['isCurrentImageComposite']) {
        if (state.currentImage.state === 'initial' && state.panoramaLocationLocked && !state.georeferencingFirstShot) { //from route geolocalise/:id/align
          const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
          const currentPanorama = state.panoramaImages[panoramaIndex];
          previousPose = currentPanorama.firstPanoramaPose;

        // In validation page, lock camera to the location used by the volunteer
        } else if (state.currentImage.state === 'waiting_validation' || state.currentImage.state === 'validated') { //from route validate/:id
            previousPose = state.currentPose;
        }
      }

      let latitude, longitude, altitude, locationLocked, roll, tilt;
      if (previousPose) {//For composite_images, use roll, focal and tilt from first geolocalised part. Only change azimuth.
        latitude = previousPose.latitude;
        longitude = previousPose.longitude;
        altitude = previousPose.altitude;
        roll = previousPose.roll;
        tilt = previousPose.tilt;
        locationLocked = true;
      } else {
        latitude = state.currentPoseApriori.latitude;
        longitude = state.currentPoseApriori.longitude;
        altitude = state.currentPoseApriori.altitude;
        locationLocked = state.currentPoseApriori.locationLocked;
        roll = state.currentPoseApriori.roll;
        tilt = state.currentPoseApriori.tilt;
      }

      const computeElement = {
        latitude,
        longitude,
        altitude,
        azimuth: state.currentPoseApriori.azimuth,
        tilt,
        roll,
        image_id: state.currentImage.id,
        height: cropRegion ? cropRegion[3] : state.currentImage.height,
        width: cropRegion ? cropRegion[2] : state.currentImage.width,
        locationLocked,
        gcps: imageCropGCPs,
      };
      if (state.currentImage.framing_mode === 'composite_image') {
        computeElement.regionByPx = cropRegion;
      }
      computePose(computeElement)
        .then(response => {
          const { GCPs, ...pose } = response.data;
          //for panoramas, use roll, focal and tilt from first geolocalised part. Only change azimuth.
          if (previousPose) {
            pose.roll = previousPose.roll;
            pose.focal = previousPose.focal;
            pose.tilt = previousPose.tilt;
          }
          //Reset GCPs offset if image cropped
          let computedGCPs = GCPs;
          if (cropRegion) {
            computedGCPs = GCPs.map( gcp => {
              return {
                ...gcp,
                x: gcp.x+cropRegion[0],
                xReproj: gcp.xReproj+cropRegion[0],
                y: gcp.y+cropRegion[1],
                yReproj: gcp.yReproj+cropRegion[1]
              };
            });
          }
          commit('SET_POSE', pose);
          commit('LOAD_GCPs_SET', computedGCPs);
          commit('app/DISABLE_FULL_LOADING', null, { root: true });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  resetPoseWithInitialPose({ commit }) {
    commit('REVERT_POSE_FROM_INITIAL_POSE');
  },

  resetAprioriWithPose({ commit }) {
    commit('REVERT_APRIORI_POSE_FROM_POSE');
  },

  saveInitialPose({ commit }) {
    commit('SAVE_INITIAL_POSE');
  },

  nextStep({ commit }) {
    commit('NEXT_STEP');
  },

  selectMode({ commit }, mode) {
    commit('SET_MODE', mode);
  },

  setPanoramaSplit({ commit }, rect) {
    commit('SET_PANORAMA_IMAGE', rect);
  },

  savePanoramaSplit({ commit }) {
    commit('SAVE_PANORAMA_IMAGE');
  },

  async savePanoramaImages({ commit, state }) {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    const currentPanorama = state.panoramaImages[panoramaIndex];
    for (let i = 0; i < currentPanorama.images.length; i += 1) {
      // Save Geoloc
      try {
        await stopGeolocalisation({
          id: currentPanorama.geolocId[i].id,
          image_id: state.currentImage.id,
          longitude: currentPanorama.currentPose[i].longitude,
          latitude: currentPanorama.currentPose[i].latitude,
          altitude: currentPanorama.currentPose[i].altitude,
          roll: currentPanorama.currentPose[i].roll,
          tilt: currentPanorama.currentPose[i].tilt,
          azimuth: currentPanorama.currentPose[i].azimuth,
          focal: currentPanorama.currentPose[i].focal,
          gcps: currentPanorama.GCPs[i],
          regionByPx: [
            Math.round(currentPanorama.images[i].x),
            Math.round(currentPanorama.images[i].y),
            Math.round(currentPanorama.images[i].width),
            Math.round(currentPanorama.images[i].height)
          ]
        });
      } catch(error) {
        commit('STORE_SAVE_ERROR', {
          index: panoramaIndex,
          error: currentPanorama.images[i],
        });
      }

      // Save Footprint
      try {
        await storeFootprint(currentPanorama.geolocId[i].id, {
          latitude: state.currentPose.latitude,
          longitude: state.currentPose.longitude,
          footprint_geojson: currentPanorama.footprint_geojson[i]
        });
      } catch(error) {
        commit('STORE_SAVE_ERROR', {
          index: panoramaIndex,
          error: currentPanorama.footprint_geojson[i],
        });
      }
    }
    commit('SAVE_STATUS', state.panoramaImages[panoramaIndex].error.length === 0);
  },

  editPanoramaSplit({ commit }, index) {
    commit('EDIT_PANORAMA_IMAGET', index);
  },

  updatePanoramaSplit({ commit }, split) {
    commit('UPDATE_PANORAMA_IMAGE', split);
  },

  stopEdition({ commit }) {
    commit('STOP_PANORAMA_IMAGE_EDITION');
  },

  deletePanoramaSplit({ commit }, index) {
    commit('DETELE_PANORAMA_IMAGE', index);
  },

  async setupSelectedSplitImage({ commit, state }, split) {
    const panoramaIndex = state.panoramaImages.findIndex(e => e.imageId === state.currentImage.id);
    if (!state.panoramaImages[panoramaIndex].geolocId[split.index]) {
      const params = {
        image_id: state.currentImage.id
      };
      const response = await startGeolocalisation({ ...params });
      commit('SET_CURRENT_PANORAMA_GEOLOCALISATION', { geoloc: response.data, panorama: split });
    }
    commit('SELECT_SPLIT_IMAGE', split);
  },

  resetSelectedSplitImage({ commit }) {
    commit('RESET_SPLIT_IMAGE');
  },

  deleteCurrentPanorama({ commit }) {
    commit('DELETE_CURRENT_PANORAMA');
  },

  lockPanoramaLocation({ commit }, isLocked) {
    commit('LOCK_PANORAMA_LOCATION', isLocked);
  },

  checkStatePanorama({ commit }) {
    commit('CHECK_STATE_PANORAMA');
  },

  enablePanoramaUnlockOption({ commit }, isEnabled) {
    commit('ENABLE_PANORAMA_UNLOCK_OPTION', isEnabled);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
