import Cookies from 'js-cookie';
import { createI18n } from 'vue-i18n';

import en from '@/locales/en.json';

import store from '@/store';

const loadedLanguages = [];

export function getLanguage() {
  // User or system can force a link to be rendered with a certain language (useful for SEO)
  const urlParams = new URLSearchParams(window.location.search);
  const langQueryParam = urlParams.get('lang');

  const userCookie = Cookies.getJSON('User');
  const savedLanguage = userCookie?.user?.userInfo?.lang;
  const navLang = navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0];

  let lang = langQueryParam || savedLanguage || navLang || 'en';
  if (!['en', 'de', 'fr', 'it', 'pt'].includes(lang)) {
    lang = 'en';
  }

  loadLanguageAsync(lang).then(() => {
    store.commit('user/SET_LANGUAGE', lang);
  });
  return lang;
}

export function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.global.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
    response => {
      loadedLanguages.push(lang);
      i18n.global.setLocaleMessage(lang, response.default);
      setI18nLanguage(lang);
    }
  );
}

export const i18n = createI18n({
  warnHtmlInMessage: 'off',
  locale: getLanguage(),
  allowComposition: true,
  fallbackLocale: 'en',
  messages: { 'en': en },
  PostTranslationHandler: (str) => str.replace(' ?', '\xa0?').replace(' !', '\xa0!')
});

export default i18n;
