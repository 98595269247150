import { getInfo, updateInfo } from '@/api/me';
import { getLanguage, i18n, loadLanguageAsync, setI18nLanguage } from '@/plugins/vue-i18n';

import Cookies from 'js-cookie';

import { login } from '@/api/authentication';
import { md5 } from '@/utils/hash';

const state = {
  token: null,
  userInfo: {
    lang: getLanguage(),
    id: 14 // Id 14 = Anonymous
  },
  avatar: null,
  roles: [],
  hasAcceptedCookiesPolicy: null,
};

const getters = {
  isLoggedIn: state => {
    return !!state.token;
  },
  isValidator: state => {
    return (state.roles.includes('super_admin') || state.roles.includes('owner_admin') || state.roles.includes('owner_validator'));
  },
  isOwnerValidator: state => {
    return state.roles.includes('owner_validator');
  },
  user_id: state => { return state.userInfo.id; }
};

const mutations = {
  SET_LANGUAGE: (state, lang) => {
    state.userInfo.lang = lang;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_AVATAR: (state, url) => {
    state.avatar = url;
  },
  RESET_TOKEN: (state) => {
    state.token = null;
  },
  RESET_AVATAR: (state) => {
    state.avatar = null;
  },
  RESET_USER_ID: (state) => {
    state.userInfo.id = 14; // Id 14 = Anonymous
  },
  ACCEPT_COOKIE_POLICY: (state) => {
    state.hasAcceptedCookiesPolicy = true;
  },
  REJECT_COOKIE_POLICY: (state) => {
    state.hasAcceptedCookiesPolicy = false;
  }
};

const actions = {
  async setLanguage({ commit }, lang) {
    if (i18n.global.availableLocales.includes(lang)) {
      commit('SET_LANGUAGE', lang);
      setI18nLanguage(lang);
    } else {
      await loadLanguageAsync(lang).then(() => {
        commit('SET_LANGUAGE', lang);
      });
    }
  },
  // user login
  login({ commit, dispatch }, userInfo) {
    const { email, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password })
        .then(response => {
          const { data } = response;
          commit('SET_TOKEN', data.token);
          dispatch('getInfo').then(() => {
            resolve();
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  oAuthSuccess({ commit, dispatch }, token) {
    commit('SET_TOKEN', token);
    dispatch('getInfo');
  },
  // Get user info
  async getInfo({ commit, state, dispatch }) {
    const { data } = await getInfo();

    return new Promise((resolve, reject) => {
      if (!data) {
        commit('RESET_TOKEN');
        return reject('Verification failed, please Login again.');
      }

      const { roles, ...userInfo } = data;

      // roles must be a non-empty array
      if (!roles || roles.length <= 0) {
        return reject('getInfo: roles must be a non-null array!');
      }

      commit('SET_ROLES', roles);
      commit('SET_USER_INFO', userInfo);
      dispatch('setLanguage', userInfo.lang);
      if (state.avatar === null) {
        dispatch('getGravatarImage', userInfo.email);
      }
      resolve(data);
    });
  },

  updateLang({ commit, state, dispatch }, lang) {
    dispatch('setLanguage', lang);
    return update({ commit, state }, { lang: lang });
  },
  // user logout
  logout({ commit, dispatch }) {
    return new Promise((resolve) => {
      commit('SET_ROLES', []);
      commit('RESET_TOKEN');
      commit('RESET_AVATAR');
      commit('SET_USER_INFO', {
        lang: getLanguage(),
        id: 14 // Id 14 = Anonymous
      });
      dispatch('observations/resetAllObsValues', null, { root: true })
        .then(() => {
          resolve();
        });
    });
  },
  getGravatarImage({ commit }, email) {
    const emailHash = md5(email);

    fetch('https://www.gravatar.com/avatar/' + emailHash + '?d=404&r=g')
      .then((res) => {
        if (res.status === 200) {
          commit('SET_AVATAR', 'https://www.gravatar.com/avatar/' + emailHash + '?r=g&s=50');
        }
      });
  },

  updateCookiesPolicyStatus({ commit }, status) {
    if (status === 'accept') {
      commit('ACCEPT_COOKIE_POLICY');
      sessionStorage.removeItem('User-Token');
    }
    if (status === 'reject') {
      Cookies.remove('User-Token');
      Cookies.remove('User');
      Cookies.remove('Views');

      commit('REJECT_COOKIE_POLICY');
    }
  }
};

function update({ commit, state }, changes) {
  return new Promise((resolve, reject) => {
    updateInfo(changes)
      .then(response => {
        const { status } = response;

        if (status != 200) {
          reject('Update failed, please try again later.');
        }

        const userInfo = { ...changes, ...state.userInfo };

        commit('SET_USER_INFO', userInfo);
        resolve('update successful');
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
